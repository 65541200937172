<template>
  <body-layout :style="backCss">
    <div class="actions flex" slot="actions">
      <a-button type="primary" class="mr-3 re" v-has="'mytask:list:button:batch_download_local'">
        还原
      </a-button>
      <a-button type="primary" class="mr-3 delete" v-has="'mytask:list:button:batch_download_local'">
        删除
      </a-button>
    </div>
    <div class="table-page-search-wrapper" slot="search" v-has="'mytask:list:button:search_user'">
      <a-form layout="inline" @keyup.enter.native="searchQueryForm" v-has="'production:list:button:search'">
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-input placeholder="搜索名称" v-model="name" style="width: 200px" />
          </a-col>
          <a-button type="primary" icon="search" @click="searchQueryForm">搜索</a-button>
        </a-row>
      </a-form>
    </div>
    <div v-show="selectedRowKeys.length>0" class="span-div">
      <a-icon type="exclamation-circle" theme="filled" style="margin-right:40px;" />已选择<span>{{selectedRowKeys.length}}</span>项
    </div>
    <a-table style="margin-top:10px;" :rowSelection="rowSelection" ref="table" size="middle" rowKey="id" :columns="columns" :dataSource="dataSource" :customRow="clickRow" :pagination="ipagination" :loading="loading" @change="handleTableChange" :scroll="{ x: 'max-content', y: 'calc(100vh - 457px)' }">
      <span slot="recycler" slot-scope="text, record">{{ record.recycler.userName}}</span>
    </a-table>
  </body-layout>
</template>

<script>
import { postAction } from '@/api/manage'
import Vue from 'vue'
export default {
  data() {
    return {
      name: '',
      color: '',
      loading: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'recycleSort',
          sorter: true,
          width: 100,
        },
        {
          title: '名称',
          dataIndex: 'recycleName',
          sorter: true,
          width: 180,
        },
        {
          title: '位置',
          dataIndex: 'recyclePosition',
          sorter: true,
          width: 280,
        },
        {
          title: '删除人',
          dataIndex: 'recycler',
          width: 280,
          sorter: true,
          scopedSlots: { customRender: 'recycler' },
        },
        {
          title: '删除时间',
          dataIndex: 'gmtRecycled',
          sorter: true,
        },
      ],
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      url: {
        list: '/recycleBin/page',
      },
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      clickRow: (record, index) => ({
        on: {
          click: () => {
            this.openDetail(record)
          },
        },
      }),
      isorter: {
        column: 'createTime',
        order: 'desc',
      },
      searchFormData: {
        size: 1,
        current: 1,
        orders: [
          {
            column: 'recycleSort',
            asc: true,
          },
        ],
      },
    }
  },
  computed: {
    backCss() {
      let color = this.color
      let backColor = this.$store.state.app.color
      return {
        '--back-color': color,
        '--theme-color': backColor,
      }
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectionRows = selectedRows
        },
        columnWidth: 80,
      }
    },
  },
  created() {
    this.loadData()
  },
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
          }
        }
      }
    }, 100)
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      //分页、排序、筛选变化时触发
      //TODO 筛选
      this.savePageSize2LS(this.url.list, pagination.pageSize)
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field
        this.isorter.order = 'ascend' == sorter.order ? 'asc' : 'desc'
      }
      this.ipagination = pagination
      this.loadData()
    },
    savePageSize2LS(url, pageSize) {
      let setting = Vue.ls.get('pagination_setting') || {}
      if (!url) return
      let index = url.path ? url.path.indexOf('?') : url.indexOf('?')
      if (index > 0) {
        url = url.substring(0, index)
      }
      setting[url] = pageSize
      Vue.ls.set('pagination_setting', setting)
    },
    async loadData() {
      const res= await postAction(this.url.list,this.searchFormData)
      this.searchFormData.offsets=res.data.offsets
      this.dataSource=res.data.records;
    },
    openDetail(row) {},
    searchQueryForm() {},
  },
}
</script>

<style lang="less" scoped>
.re {
  background-color: #e6a23c;
  border-color: #e6a23c;
  &:hover {
    background-color: #f7ae41;
    border-color: #f7ae41;
  }
}
.delete {
  background-color: #f56c6c;
  border-color: #f56c6c;
  &:hover {
    background-color: #ff6f6f;
    border-color: #ff6f6f;
  }
}
i {
  /* background-color: var(--theme-color); */
  color: var(--theme-color);
}
.span-div {
  padding-left: 32px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 4px;
  background-color: var(--back-color);
  border: 1px solid var(--theme-color);
  > span {
    color: var(--theme-color);
    font-weight: 700;
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>